import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import { reportEndPoints, userEndPoints } from "../../services/apis"; // Import process API
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import checkRoleAccess from "../shared/CheckRoleAcess";
import Loader from "../../utils/Loader.jsx";
import NoDataFound from "../../utils/NoDataFound.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MissingHour = () => {
  const [missingHourReportData, setMissingHourReportData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employeeName, setEmployeeName] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  // Optimized filtering logic

  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setHours(23, 59, 59, 999);
  const filteredMissingHourReportData = missingHourReportData
    .filter((missing_hours) => {
      const matchEmployeeName = employeeName
        ? missing_hours.ID === employeeName
        : true;

      const taskDate = new Date(missing_hours.Date);
      const isWithinDateRange =
        startDate && endDate
          ? taskDate >= startDate && taskDate <= adjustedEndDate
          : true;

      return matchEmployeeName && isWithinDateRange;
    })
    .sort((a, b) => {
      if (sortConfig.key === null) return 0;

      const aVal = a[sortConfig.key];
      const bVal = b[sortConfig.key];

      // Check if sorting by date field
      if (sortConfig.key === "task_effort_time") {
        const dateA = new Date(aVal);
        const dateB = new Date(bVal);
        if (dateA < dateB) return sortConfig.direction === "asc" ? -1 : 1;
        if (dateA > dateB) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      } else {
        // Handle string sorting
        const aValStr = aVal?.toString().toLowerCase() || "";
        const bValStr = bVal?.toString().toLowerCase() || "";
        if (aValStr < bValStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aValStr > bValStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
    });

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(userEndPoints.GET_ALL_ACTIVE_USERS);
      setEmployeeData(response.data?.data?.data || []);

      console.log("All Users:", response.data?.data?.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const employeeNameOption = [
    ...new Set(
      employeeData
        .filter((employee) => employee.id && employee.name)
        .map((employee) => `${employee.id}:${employee.name}`)
    ),
  ].map((item) => {
    const [id, name] = item.split(":");
    return { value: id, label: name };
  });

  const fetchMissingHourData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(reportEndPoints.GET_ALL_Missing_report);
      setMissingHourReportData(response.data?.data?.data || []);
    } catch (error) {
      console.error("Error fetching processes:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setUserId(storedUserId);

    fetchEmployeeData();
    fetchMissingHourData();
  }, [userId]);

  useEffect(() => {
    const role = [1, 2, 7, 8];
    if (!checkRoleAccess(role)) {
      return;
    }
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${d.getFullYear()}-${month}-${day}`;
  };

  return (
    <div className="container mx-auto p-4">
      <HelmetProvider>
        <Helmet>
          <title>MSL- Tasks</title>
        </Helmet>
      </HelmetProvider>

      <div className="flex justify-between items-center mb-4">
        <h1 className="text-4xl font-bold mb-4">Missing Hour Report</h1>
      </div>

      <div className="flex flex-wrap -mx-2">
        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6 ">
          <label
            htmlFor="employee-select"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Employee Name
          </label>
          <Select
            classNamePrefix="form-select"
            placeholder="Search..."
            options={employeeNameOption}
            value={
              employeeNameOption.find(
                (option) => option.value === employeeName
              ) || null
            }
            isClearable
            onChange={(selectedOption) => {
              setEmployeeName(selectedOption ? selectedOption.value : null);
            }}
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-6">
          <label
            htmlFor="date-range"
            className="block text-sm font-semibold text-gray-600 mb-2"
          >
            Date Range
          </label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            dateFormat="yyyy/MM/dd"
            isClearable={true}
            placeholderText="Select date range"
            className="rounded-md border border-gray-300 focus:border-blue-500 shadow-sm"
          />
        </div>
      </div>

      {loading ? (
        <Loader /> // Show loader while loading
      ) : filteredMissingHourReportData.length === 0 ? (
        <NoDataFound /> // Show no data found component
      ) : (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("Name")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Employee Name</span>
                    {getSortIcon("Name")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("Date")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Date</span>
                    {getSortIcon("Date")}
                  </div>
                </th>
                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("Efforts")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Effort Time(hour)</span>
                    {getSortIcon("Efforts")}
                  </div>
                </th>

                <th
                  className="border-b border-gray-600 px-6 py-4 text-left text-sm font-semibold uppercase tracking-wide cursor-pointer hover:bg-gray-700 transition-colors duration-300"
                  onClick={() => requestSort("Missing_Hours")}
                >
                  <div className="flex  items-center gap-2">
                    <span>Missing Hours</span>
                    {getSortIcon("Missing_Hours")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMissingHourReportData.map((task, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } hover:bg-gray-100`}
                >
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.Name}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {new Date(task.Date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.Efforts}
                  </td>
                  <td className="border border-gray-300 p-3 text-gray-700">
                    {task.Missing_Hours}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MissingHour;
